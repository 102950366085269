<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';
	import Gen from '@helper/Gen';

	export default {
		name: "ProjectNews",
		extends: Base,
		data() {
			return {
				Name: "ProjectNews",
				project_id : "",
				viewType :'list',
				row:{},
				row1:{},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
			this.project_id = App.$route.params.id
			this.filter.status=''
		},
		methods:{
			search(){
		      var query = Object.assign(Gen.clone(this.$route.query),Gen.clone(this.filter))
		      delete query.page
		      this.$router.push({name:this.Name,params:{id:App.$route.params.id}, query: query})
		    },
		    addNews(){
		    	this.row ={}
		    	this.row.type = "add"
		    	this.row.mpa_project = App.$route.params.id
		    	this.viewType = "form"
		    },
		    edit(v){
		    	this.row = v		    	
		    	this.row.type = "update"
		    	this.row.mpa_project = App.$route.params.id
		    	this.viewType = "form"
		    },
		    
		    submitForm(e, callback){
		      if(e&&e.btnLoading()) return;
		      this.row.mpa_project = App.$route.params.id
		      BOGen.apirest("/"+this.Name, this.row, (err, resp)=>{
		        if(e) e.btnUnloading()
		        if(err){
		          if(err.status == 422){
		            Object.keys(err.responseJSON).forEach((k)=>{
		              $("[error="+k+"]").html("<label class='error'>"+err.responseJSON[k][0]+"</label>")
		            })
		            return
		          }
		          return swal(err.resp.message, err.resp.desc, 'warning')
		        }
		        if(resp.error){
		          if(this.errorFormCallback) return this.errorFormCallback(resp);
		          if(callback) return callback(resp);
		          return Gen.info(resp.message, "danger")
		        }
		        if(resp.success){
		          if(this.successFormCallback) return this.successFormCallback(resp);
		          if(callback) return callback(resp);
		          return Gen.info(resp.message, "success", 1000).then(()=>{
		          	this.viewType="list"
		            this.$router.push({name:this.Name,params:{id:App.$route.params.id}})
		          })
		        }
		      },"POST")
		    },
		    submitFormHeader(e, callback){
		      if(e&&e.btnLoading()) return;
		      this.row1.sc_project = App.$route.params.id
		      this.row1.type = 'header'
		      BOGen.apirest("/"+this.Name, this.row1, (err, resp)=>{
		        if(e) e.btnUnloading()
		        if(err){
		          if(err.status == 422){
		            Object.keys(err.responseJSON).forEach((k)=>{
		              $("[error="+k+"]").html("<label class='error'>"+err.responseJSON[k][0]+"</label>")
		            })
		            return
		          }
		          return swal(err.resp.message, err.resp.desc, 'warning')
		        }
		        if(resp.error){
		          if(this.errorFormCallback) return this.errorFormCallback(resp);
		          if(callback) return callback(resp);
		          return Gen.info(resp.message, "danger",2000,'.info-header')
		        }
		        if(resp.success){
		          if(this.successFormCallback) return this.successFormCallback(resp);
		          if(callback) return callback(resp);
		          return Gen.info(resp.message, "success", 1000,'.info-header').then(()=>{
		            this.$router.push({name:this.Name,params:{id:App.$route.params.id}})
		            this.viewType="list"
		          })
		        }
		      },"POST")
		    },
		    listNews(){
		    	this.viewType = 'list'
		    }
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.project'() {
				this.search()
			},
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		<div class="topnavBackoffice">
        <router-link :to="{name:'ProjectHome',params:{id:$route.params.id}}">Beranda</router-link>
        <router-link :to="{name:'ProjectProducts',params:{id:$route.params.id}}">Produk</router-link>
        <router-link :to="{name:'ProjectAbout',params:{id:$route.params.id}}">Tentang Kami</router-link>
        <a href="javascript:;" class="dropdown_bo active">Artikel & Progres
          <ul class="dropdown_menu">
            <li><router-link :to="{name:'ProjectNews',params:{id:$route.params.id}}">Artikel</router-link></li>
            <li><router-link :to="{name:'ProjectProgress',params:{id:$route.params.id}}">Perkembangan Proyek</router-link></li>
          </ul>
        </a>
        <router-link :to="{name:'ProjectContact',params:{id:$route.params.id}}">Lokasi & Kontak</router-link>
    </div>
		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="viewType=='list'">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <div class="card">
              <div class="card-body">
                  <h5 class="card-title">Header Section</h5>
                  <VForm @resp="submitFormHeader" method="post">
                  <div class="row">
                      <div class="col-md-12 info-header"></div>
                      <div class="col-md-6">
                          <BoField name="sc_title" v-model="row1.sc_title" mandatory></BoField>
                      </div>
                      <div class="col-md-12">
                          <BoField name="sc_desc1" mandatory :label="'Content'">
                              <CKEditor name="sc_desc1" class="form-control" v-model="row1.sc_desc"></CKEditor>
                          </BoField>
                      </div>
                      <div class="col-md-12 text-right"><button type="submit" class="btn btn-rounded btn-info btn-loading">Update Header</button></div>
                  </div>
                  </VForm>
              </div>
          </div>
      </div>
        	<!-- End -->
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row">
								<div class="col-sm-2">
									<h5 class="card-title">{{ObjectName}} List </h5>
								</div>
								<div class="col-sm-3 ml-auto">
									<select v-model="filter.status" @change="search()" class="form-control">
										<option value="">-- Filter Status --</option>
										<option value="D">Draft</option>
										<option value="P">Publish</option>
									</select>
								</div>
								<div class="col-auto">
									<div class="form-group mb-0 d-inline-block mr-1">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
									<router-link :to="{name:Name,params:{id:project_id}}" class="btn btn-warning">Reset</router-link>&nbsp;
								</div>
								<div class="col-auto">
									<a href="javascript:;" class="btn btn-info" @click="addNews"><i class="icon icon-plus"></i> Add News</a>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="mpa_title"></SortField>
									</th>
									<th>{{fields.mpa_author}}</th>
									<th>{{fields.mpa_publish_date}}</th>
									<th>Status</th>
									<th width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{v.mpa_title}}</td>
									<td>{{v.mpa_author}}</td>
									<td>{{(v.mpa_publish_date||"").dates("format")}}</td>
									<td>
										<StatusLabel :type="'blog'" :status="v.status"></StatusLabel>
									</td>
									<td class="btn-action">
										<a class="icon_action" href="javascript:;" @click="edit(v)" v-tooltip="'Edit'">
											<i class="ti-marker-alt"></i>
										</a>
										<a href="javascript:;" class="icon_action" @click="deleteItem($event,k)" v-tooltip="'Remove'">
											<i class="ti-trash"></i>
										</a>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card-body">
						<Pagination class="mb-0 justify-content-end" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="viewType=='form'">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-10">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
						<div class="col-md-2">
							<a href="javascript:;" @click="listNews()" class="btn btn-success d-lg-block m-l-15"><i class="fas fa-list-ul m-r-15"></i>List News</a>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-8">
							<BoField name="mpa_title" v-model="row.mpa_title"></BoField>
							<input type="hidden" name="mpa_project" v-model="row.mpa_project">
							<BoField name="mpa_desc" >
								<CKEditor name="content" class="form-control" v-model="row.mpa_desc"></CKEditor>
							</BoField>
							<BoField name="mpa_ab_id" >
									<select  v-model="row.mpa_ab_id" class="form-control">
										<option value="">-- Select Banner --</option>
										<option v-for="(v,k) in mrBanner" :key="k" :value="v.ab_id">{{v.ab_name}}</option>
									</select>
							</BoField>
						</div>
						<div class="col-4">
							<BoField name="mpa_img_thumbnail">
								<Uploader name="mpa_img_thumbnail"  :param="{thumbnail:true}" type="newsThumb" uploadType="cropping" v-model="row.mpa_img_thumbnail"></Uploader>
							</BoField>
							<BoField name="mpa_img_thumbnail_alt" v-model="row.mpa_img_thumbnail_alt"></BoField>
							<BoField name="mpa_img_detail">
								<Uploader name="mpa_img_detail"  :param="{thumbnail:true}" type="newsDetail" uploadType="cropping" v-model="row.mpa_img_detail"></Uploader>
							</BoField>
							<BoField name="mpa_img_detail_alt" v-model="row.mpa_img_detail_alt"></BoField>
						</div>
						<div class="col-6">
							<BoField name="mpa_author" v-model="row.mpa_author"></BoField>
							<BoField name="mpa_tags">
								<TagsInput name="mpa_tags" style="display:block" v-model="row.mpa_tags"
									:attr="validation('mpa_tags')"></TagsInput>
							</BoField>
							<BoField name="mpa_meta_description" >
								<textarea class="form-control" rows="6" v-model="row.mpa_meta_description"></textarea>
							</BoField>
						</div>
						<div class="col-6">
							<BoField name="mpa_publish_date">
								<DatePicker v-model="row.mpa_publish_date" placeholder="Publish Date"></DatePicker>
							</BoField>
							<BoField name="mpa_meta_keyword">
								<TagsInput name="mpa_meta_keyword" style="display:block" v-model="row.mpa_meta_keyword"
									:attr="validation('mpa_meta_keyword')"></TagsInput>
							</BoField>
							<BoField name="mpa_is_active">
								<div class="row">
									<radio name="mpa_is_active" v-model="row.mpa_is_active" option="P"
										:attr="validation('mpa_is_active')">Publish</radio>
									<radio name="mpa_is_active" v-model="row.mpa_is_active" option="D">Draft</radio>
								</div>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-rounded btn-loading">Save News</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>